<!-- vista header -->
<div class="container-fluid">
  <div class="row box__preguntas info__interior py-4">
    <div class="col-6 col-sm-6 col-md-8 col-lg-10 ps-4">
      <a (click)="irHome()">
        <img src="../assets/img/logo_otic_header.svg" alt="" width="120px">
      </a>
    </div>
    <div class="col-3 col-sm-3 col-md-2 col-lg-1 pe-0 justify-content-end">
      <img src="../assets/img/logo_consulta_header.svg" alt="" width="50px">
    </div>
    <div class="col-3 col-sm-3 col-md-2 col-lg-1 ps-0 box__consper" *ngIf="!mostrarIcono">
      <div class="d-flex justify-content-end">
        <span class="text-12-bold text-white">CONSULTA</span>
      </div>
      <div class="d-flex justify-content-start">
        <span class="text-12-bold text-calypso">PERSONAS</span>
      </div>
    </div>
    <div class="col-3 col-sm-3 col-md-2 col-lg-1 pe-4 justify-content-end" *ngIf="mostrarIcono">
      <img src="../assets/img/cuenta-circulo-blanco.svg" alt="" width="37px">
    </div>
  </div>
</div>
