// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  SW: false,
  url_persona_qr: '',
  password_documentos:'',

  REGION: 'us-east-1',
  IDENTITY_POOL_ID: '',
  COGNITO_IDP_ENDPOINT: '',
  USER_POOL_ID: 'us-east-1_k6Pf6gdwX',
  CLIENT_ID: '75e88kmjbtplcktgrre9vv5rn4',
  COGNITO_IDENTITY_ENDPOINT: '',
  STS_ENDPOINT: '',
  STAGE: 'prod',

  API_DOMAINS: {
    USUARIOS: 'https://serv.sca.oticsofofa.cl/servicio/usuarios/login',
    QR: 'https://api-qr.gestorcapitalhumano.cl/qr',
    COMUNES: 'https://api-qr.gestorcapitalhumano.cl/comunes',
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
